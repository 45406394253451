import React from 'react';
import './DiamondInfo.css';
import diamondImage from '../../image/Grey Luxury Exclusive Diamond Luxury Jewelry Instagram Post.jpg';
import { Helmet } from 'react-helmet';

const DiamondInfo = () => {
    return (
        <div className="diamond-info-container">
            <Helmet>
                <title>About Us - Luxury Diamond Jewelry | Your Boutique Name</title>
                <meta name="description" content="Discover the epitome of luxury and sophistication with our exquisite collection of diamonds. From engagement rings to stunning jewelry, find the perfect piece to celebrate your special moments." />
                <meta name="keywords" content="diamond jewelry, luxury diamonds, engagement rings, fine jewelry, boutique diamonds" />
                <meta property="og:title" content="About Us - Luxury Diamond Jewelry | Your Boutique Name" />
                <meta property="og:description" content="Discover the epitome of luxury and sophistication with our exquisite collection of diamonds. From engagement rings to stunning jewelry, find the perfect piece to celebrate your special moments." />
                <meta property="og:image" content={diamondImage} />
                <meta property="og:url" content="http://www.yourboutiquewebsite.com/about-us" />
                <script type="application/ld+json">
                    {`
                        {
                            "@context": "http://schema.org",
                            "@type": "AboutPage",
                            "name": "About Us - Luxury Diamond Jewelry",
                            "description": "Discover the epitome of luxury and sophistication with our exquisite collection of diamonds. From engagement rings to stunning jewelry, find the perfect piece to celebrate your special moments.",
                            "image": "${diamondImage}",
                            "url": "http://www.yourboutiquewebsite.com/about-us"
                        }
                    `}
                </script>
            </Helmet>
            <div className="diamond-image-container">
                <img src={diamondImage} alt="Diamond" className="diamond-image" />
            </div>
            <div className="diamond-details">
                <h2 className="diamond-title">About us</h2>
                <p className="diamond-description">
                    Diamonds, the epitome of luxury and sophistication, have long been cherished as symbols of enduring love and timeless elegance. At our boutique, we pride ourselves on offering the finest selection of diamonds, sourced from the world's most renowned mines and crafted by master artisans.
                    Each diamond in our collection is meticulously chosen for its exceptional quality, unrivaled brilliance, and exquisite craftsmanship. From classic round cuts to dazzling emerald shapes, our diamonds are a testament to the artistry and precision that goes into creating these extraordinary gems.
                    Whether you're searching for the perfect engagement ring to symbolize your everlasting commitment or a stunning piece of jewelry to commemorate a special milestone, our expert team is dedicated to helping you find the diamond of your dreams.
                </p>
                <button className="learn-more-button">Learn More</button>
            </div>
        </div>
    );
}

export default DiamondInfo;
