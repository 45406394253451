import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS
import L from 'leaflet'; // Import Leaflet icon
import "./leaflet.css"; // Import your custom CSS
import companyLogo from '../../image/logo-removebg-preview.png'; // Import your company logo image

delete L.Icon.Default.prototype._getIconUrl;

const officeLocation = [21.1702, 72.8311];

const customIcon = L.icon({
    iconUrl: companyLogo,
    iconSize: [25, 25], // Set the size of the icon
    iconAnchor: [32, 64], // Set the anchor point of the icon
    popupAnchor: [0, -64], // Set the popup anchor
});

const Index = () => {
    return (
        <div className="map-container"> {/* Use a div to wrap the map container */}
            <MapContainer
                center={officeLocation}
                zoom={13}
                style={{ height: '100%', width: '100%' }} // Set map height and width
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker position={officeLocation} icon={customIcon}> {/* Set custom icon */}
                    <Popup>
                        Office Location in Surat, Gujarat, India
                    </Popup>
                </Marker>
            </MapContainer>
        </div>
    );
};

export default Index;
