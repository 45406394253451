import React from 'react';
import image from "../../image/0001-Model_b842d6a2-cd67-48b2-a712-d0f42c5e8073_360x.webp";
import image1 from "../../image/classic-braselets-760x584w_360x.webp";
import image2 from "../../image/earings_360x.webp";
import image3 from "../../image/PD1205_Model_360x.webp";
import './product.css';
import { Helmet } from 'react-helmet';

export default function Product() {
    return (
        <div className="product-container">
            <Helmet>
                <title>Discover Exquisite Jewelry Collections - LalaDiamond</title>
                <meta name="description" content="Explore our exquisite collection of fine jewelry including rings, bracelets, earrings, and pendants. Each piece is crafted with precision and passion to adorn you with elegance and grace." />
                <meta name="keywords" content="fine jewelry, rings, bracelets, earrings, pendants, LalaDiamond" />
                <meta property="og:title" content="Discover Exquisite Jewelry Collections - LalaDiamond" />
                <meta property="og:description" content="Explore our exquisite collection of fine jewelry including rings, bracelets, earrings, and pendants. Each piece is crafted with precision and passion to adorn you with elegance and grace." />
                <meta property="og:url" content="http://www.laladiamond.com/jewelry-collections" />
            </Helmet>
            <div className="product-grid">
                <div className='product-item'>
                    <img src={image} alt="RINGS" />
                    <p className='product-collection'>RINGS</p>
                </div>
                <div className='product-item'>
                    <img src={image1} alt="BRACELETS" />
                    <p className='product-collection'>BRACELETS</p>
                </div>
                <div className='product-item'>
                    <img src={image2} alt="EARRINGS" />
                    <p className='product-collection'>EARRINGS</p>
                </div>
                <div className='product-item'>
                    <img src={image3} alt="PENDANTS" />
                    <p className='product-collection'>PENDANTS</p>
                </div>
            </div>
        </div>
    )
}
