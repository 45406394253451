import './App.css';
import Templatepage from "./component/Templetpage"
import Header from './component/Header';
import Product from "./component/Product"
import Intro from './component/Intro';
import Cursor from './component/Cursor';
import Detail from './component/Detail';
import Carousel from './component/Carousel';
import DiamondInfo from './component/DiamondInfo/DiamondInfo';
import Security from "./component/Security"
import Typebyproduct from "./component/Typebyproduct"
import Contact from "./component/Contact"
import Footer from "./component/Footer"
import Setimage from "./component/Setimages"


function App() {
  return (
    <div>
      <Header />
      <Templatepage />
      <Product />
      <Cursor />
      <Intro />
      <Detail />
      <Carousel />
      <DiamondInfo />
      {/* <Typebyproduct /> */}
      <Setimage />
      <Security />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
