import React, { useState, useEffect } from 'react';
import './Cursor.css'; // Import the CSS file
import { Helmet } from "react-helmet";

function Cursor() {
    const [position, setPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const handleMouseMove = (e) => {
            setPosition({ x: e.clientX, y: e.clientY });
        };

        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>Interactive Cursor Effect | Lala Diamond</title>
                <meta name="description" content="Experience our interactive cursor effect on Lala Diamond's website. Move your mouse to see the custom cursor in action." />
                <meta name="keywords" content="interactive cursor, custom cursor effect, Lala Diamond, website interactivity, mouse effect" />
                <meta property="og:title" content="Interactive Cursor Effect | Lala Diamond" />
                <meta property="og:description" content="Experience our interactive cursor effect on Lala Diamond's website. Move your mouse to see the custom cursor in action." />
                <meta property="og:image" content="%PUBLIC_URL%/path-to-your-image.jpg" />
                <meta property="og:url" content="http://www.laladiamond.com/interactive-cursor" />
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "http://schema.org",
                        "@type": "WebPage",
                        "name": "Interactive Cursor Effect | Lala Diamond",
                        "description": "Experience our interactive cursor effect on Lala Diamond's website. Move your mouse to see the custom cursor in action.",
                        "url": "http://www.laladiamond.com/interactive-cursor"
                    }
                `}
                </script>
            </Helmet>
            <div className="cursor" style={{ left: position.x, top: position.y }}></div>
        </>
    );
}

export default Cursor;
