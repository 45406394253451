import React from 'react';
import './Footer.css';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

const Footer = () => {
    return (
        <footer>
            <Helmet>
                <title>Contact and Follow Us - Lala Diamond</title>
                <meta name="description" content="Contact Lala Diamond for inquiries about our diamond jewelry collections. Follow us on social media for updates and promotions." />
                <meta name="keywords" content="diamond jewelry, contact us, social media, follow us, Lala Diamond" />
                <meta property="og:title" content="Contact and Follow Us - Lala Diamond" />
                <meta property="og:description" content="Contact Lala Diamond for inquiries about our diamond jewelry collections. Follow us on social media for updates and promotions." />
                <meta property="og:url" content="http://www.laladiamond.com/contact-us" />
            </Helmet>
            <div className="footer">
                <div className="footer-section">
                    <h3>About Lala Diamond</h3>
                    <p>Lala Diamond is committed to providing the finest diamond jewelry. Our collections are crafted with precision and elegance, ensuring every piece is a masterpiece.</p>
                </div>
                <div className="footer-section">
                    <h3>Quick Links</h3>
                    <a href="#home">Home</a>
                    <a href="#shop">Shop</a>
                    <a href="#about">About Us</a>
                    <a href="#contact">Contact Us</a>
                    <a href="#faq">FAQ</a>
                </div>
                <div className="footer-section">
                    <h3>Contact Us</h3>
                    <p>Email: laladiamlimited@gmail.com</p>
                    <p>Phone: +852 61601577</p>
                    <p>Address: 1234 Jewelry Avenue, Suite 567, Diamond District, Hong Kong</p>
                </div>
                <div className="footer-section">
                    <h3>Follow Us</h3>
                    <div className="footer-socials">
                        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
                        <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
                        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
                        <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; {new Date().getFullYear()} Lala Diamond. All Rights Reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;
