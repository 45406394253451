import React from 'react';
import "./index.css";
import icone from "../../image/icones/Group 23.png";
import icone1 from "../../image/icones/Vector (3).png";
import icone2 from "../../image/icones/Vector (4).png";
import icone3 from "../../image/icones/Vector (5).png";
import { Helmet } from "react-helmet";

export default function Detail() {
    return (
        <div className="detail">
            <Helmet>
                <title>Discover the Best Diamond Jewelry Designs | Lala Diamond</title>
                <meta name="description" content="Explore the latest designs of diamond jewelry at Lala Diamond. Our certified guarantee, 30 days money-back policy, and 99% positive response rate ensure your satisfaction." />
                <meta name="keywords" content="latest diamond designs, certified guarantee, money back policy, customer satisfaction, Lala Diamond" />
                <meta property="og:title" content="Discover the Best Diamond Jewelry Designs | Lala Diamond" />
                <meta property="og:description" content="Explore the latest designs of diamond jewelry at Lala Diamond. Our certified guarantee, 30 days money-back policy, and 99% positive response rate ensure your satisfaction." />
                <meta property="og:image" content="%PUBLIC_URL%/path-to-your-image.jpg" />
                <meta property="og:url" content="http://www.laladiamond.com/latest-designs" />
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "http://schema.org",
                        "@type": "Product",
                        "name": "Latest Diamond Jewelry Designs",
                        "description": "Explore the latest designs of diamond jewelry at Lala Diamond. Our certified guarantee, 30 days money-back policy, and 99% positive response rate ensure your satisfaction.",
                        "image": "http://www.laladiamond.com/path-to-your-image.jpg",
                        "brand": {
                            "@type": "Organization",
                            "name": "Lala Diamond"
                        }
                    }
                `}
                </script>
            </Helmet>
            <div className="detail-item">
                <div>
                    <img src={icone} alt="Latest Designs" />
                </div>
                <b>LATEST DESIGNS</b>
            </div>
            <div className="detail-divider"></div>
            <div className="detail-item">
                <div>
                    <img src={icone1} alt="Certified Guarantee" />
                </div>
                <b>CERTIFIED GUARANTEE</b>
            </div>
            <div className="detail-divider"></div>
            <div className="detail-item">
                <div>
                    <img src={icone2} alt="30 Days Money Back" />
                </div>
                <b>30 DAYS MONEY BACK</b>
            </div>
            <div className="detail-divider"></div>
            <div className="detail-item">
                <div>
                    <img src={icone3} alt="99% Positive Response" />
                </div>
                <b>99% POSITIVE RESPONSE</b>
            </div>
        </div>
    );
}
