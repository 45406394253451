import React from 'react';
import "./buy.css";
import image from "../../image/12117258621535972579-128_1_180x.webp";
import image1 from "../../image/2765480521579605504-128_180x.avif";
import image2 from "../../image/3114434681535696658-128_180x.webp";
import image3 from "../../image/5441156781530273523-128_180x.webp";
import { Helmet } from 'react-helmet';

export default function Buy() {
    return (
        <div>
            <Helmet>
                <title>Why Buy From Us? - LalaDiamond</title>
                <meta name="description" content="Discover the reasons why you should buy diamond jewelry from LalaDiamond. From certified diamonds to secure delivery, we ensure the highest quality and customer satisfaction." />
                <meta name="keywords" content="diamond jewelry, certified diamonds, highest quality, secure delivery, customer satisfaction, LalaDiamond" />
                <meta property="og:title" content="Why Buy From Us? - LalaDiamond" />
                <meta property="og:description" content="Discover the reasons why you should buy diamond jewelry from LalaDiamond. From certified diamonds to secure delivery, we ensure the highest quality and customer satisfaction." />
                <meta property="og:image" content="%PUBLIC_URL%/path-to-your-image.jpg" />
                <meta property="og:url" content="http://www.laladiamond.com/why-buy-from-us" />
            </Helmet>
            <h1 className='buyh1'>Why Buy From Us?</h1>
            <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", marginTop: "50px", flexWrap: "wrap" }}>
                <div className='buy'>
                    <img src={image1} alt="Certified Diamonds" />
                    <h2>Certified Diamonds</h2>
                    <p>We only provide certified diamonds. All diamonds come with a certificate of authenticity.</p>
                </div>
                <div className='buy'>
                    <img src={image2} alt="Highest Quality" />
                    <h2>Highest Quality</h2>
                    <p>We offer only the highest quality diamonds to our customers. Unlock elegance with our exquisite diamond jewelry.</p>
                </div>
                <div className='buy'>
                    <img src={image} alt="Secure Delivery" />
                    <h2>Secure Delivery</h2>
                    <p>We deliver with our preferred shipping partners - Aramex, DHL and FedEx for secure and on-time delivery.</p>
                </div>
                <div className='buy'>
                    <img src={image3} alt="Highest Satisfaction" />
                    <h2>Highest Satisfaction</h2>
                    <p>100% satisfaction rate from 100K customers and counting, around the world!</p>
                </div>
            </div>
        </div>
    )
}
