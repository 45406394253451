import React, { useState } from "react";
import "./header.css";
import logo from "../../image//logo-removebg-preview (1).png";
import { Helmet } from 'react-helmet';

export default function Header() {
    const [showSidebar, setShowSidebar] = useState(false);

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };

    return (
        <div className="header-container">
            <Helmet>
                <title>Lala Diamond - Fine Jewellery and Diamonds</title>
                <meta name="description" content="Discover exquisite fine jewelry and diamonds at Lala Diamond. Browse our collection of high-quality diamond jewelry and explore our range of fine jewelry pieces." />
                <meta name="keywords" content="Lala Diamond, fine jewellery, diamond jewellery, high-quality diamonds, luxury jewelry" />
                <meta property="og:title" content="Lala Diamond - Fine Jewellery and Diamonds" />
                <meta property="og:description" content="Discover exquisite fine jewelry and diamonds at Lala Diamond. Browse our collection of high-quality diamond jewelry and explore our range of fine jewelry pieces." />
                <meta property="og:image" content="%PUBLIC_URL%/path-to-your-logo.png" />
                <meta property="og:url" content="http://www.laladiamond.com" />
            </Helmet>
            <div className="nav">
                <img src={logo} alt="Logo" />

                <nav className={showSidebar ? "sidebar show" : "sidebar"}>
                    <ul>
                        <li>
                            <a href="/">Home</a>
                        </li>
                        <li>
                            <a href="/">Fine Jewellery</a>
                        </li>
                        <li>
                            <a href="/">Diamond</a>
                        </li>
                        <li>
                            <a href="/about">About</a>
                        </li>
                        <li>
                            <a href="/product">Product</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}
