import React from 'react';
import "./index.css";
import img from "../../image/DSC01728copy.jpg";
import Map from "../../component/Map";
import { Helmet } from "react-helmet";

export default function ContactUs() {
    return (
        <div className='maincomponent' style={{ padding: "0 20px" }}>
            <Helmet>
                <title>Contact Us - Lala Diamond</title>
                <meta name="description" content="Get in touch with Lala Diamond, the best diamond jewelry seller in Surat. Find our contact details, location, and more information here." />
                <meta name="keywords" content="contact Lala Diamond, diamond jewelry Surat, best diamond seller Surat, diamond jewelry contact, Lala Diamond location" />
                <meta property="og:title" content="Contact Us - Lala Diamond" />
                <meta property="og:description" content="Get in touch with Lala Diamond, the best diamond jewelry seller in Surat. Find our contact details, location, and more information here." />
                <meta property="og:image" content="%PUBLIC_URL%/path-to-your-image.jpg" />
                <meta property="og:url" content="http://www.laladiamond.com/contact" />
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "http://schema.org",
                        "@type": "ContactPage",
                        "name": "Contact Us - Lala Diamond",
                        "description": "Get in touch with Lala Diamond, the best diamond jewelry seller in Surat.",
                        "image": "http://www.laladiamond.com/path-to-your-image.jpg",
                        "url": "http://www.laladiamond.com/contact"
                    }
                `}
                </script>
            </Helmet>
            <div className='intros' style={{ textAlign: "center" }}>Contact Us</div>
            <div className='content-containers'>
                <div className='bgimagess'>
                    <img src={img} alt="Lala Diamond Storefront" />
                </div>
                <div>
                    <div className='mainintro'>
                        <Map />
                    </div>
                </div>
            </div>
        </div>
    );
}
