import React from 'react';
import image1 from "../../image/image 3.png";
import image2 from "../../image/image 2.png";
import image3 from "../../image/6.webp";
import image4 from "../../image/13.png";
import './images.css'; // Import the CSS file
import { Helmet } from 'react-helmet';

export default function Index() {
    return (
        <div style={{ marginTop: "50px", padding: "0 20px" }}>
            <Helmet>
                <title>Latest Collection - LalaDiamond</title>
                <meta name="description" content="Explore the latest collection of trending jewelry at LalaDiamond. From rings to silver bracelets, earrings, and pendants, discover exquisite pieces crafted with precision and style." />
                <meta name="keywords" content="latest collection, trending jewelry, rings, silver bracelets, earrings, pendants, LalaDiamond" />
                <meta property="og:title" content="Latest Collection - LalaDiamond" />
                <meta property="og:description" content="Explore the latest collection of trending jewelry at LalaDiamond. From rings to silver bracelets, earrings, and pendants, discover exquisite pieces crafted with precision and style." />
                <meta property="og:url" content="http://www.laladiamond.com/latest-collection" />
            </Helmet>
            <p className='letestcollection'>Latest Collection</p>
            <p className='tredingjwell'>Trending Jewelry</p>
            <div className='detailset'>
                <img src={image1} alt="Ring" />
                <div>
                    <p className='silverbracelets'>Ring</p>
                    <p className='productdetail'>
                        Nestled within the delicate embrace of polished silver or gold, the ring emerges as an epitome of elegance and grace, a timeless adornment that transcends mere fashion to become a symbol.
                    </p>
                    <button className='detailbutton'>Show More</button>
                </div>
            </div>
            <div className='detailsets'>
                <div>
                    <p className='silverbracelets'>Silver Bracelets</p>
                    <p className='productdetail'>
                        Crafted with precision, these silver bracelets offer a touch of sophistication and style, perfect for any occasion.
                    </p>
                    <button className='detailbutton'>Show More</button>
                </div>
                <img src={image2} alt="Silver Bracelets" />
            </div>
            <div className='detailset'>
                <img src={image4} alt="Earrings" />
                <div>
                    <p className='silverbracelets'>Earrings</p>
                    <p className='productdetail'>
                        These exquisite earrings, whether in silver or gold, are designed to enhance your natural beauty and elegance.
                    </p>
                    <button className='detailbutton'>Show More</button>
                </div>
            </div>
            <div className='detailsets'>
                <div>
                    <p className='silverbracelets'>Pendants</p>
                    <p className='productdetail'>
                        Adorn your neck with these beautifully crafted pendants, each piece a work of art that speaks to your unique style.
                    </p>
                    <button className='detailbutton'>Show More</button>
                </div>
                <img src={image3} alt="Pendants" />
            </div>
        </div>
    )
}
