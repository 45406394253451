import React from 'react';
import "./intro.css";
import img from "../../image/4.jpg";
import { Helmet } from 'react-helmet';

export default function Intro() {
    return (
        <div style={{ padding: "0 20px", paddingBottom: "35px" }} className='containers'>
            <Helmet>
                <title>Clean and Elegant Jewelry Designs - LalaDiamond</title>
                <meta name="description" content="Explore the latest collection of clean and elegant jewelry designs at LalaDiamond. Each piece is crafted with precision and passion to adorn you with elegance and grace." />
                <meta name="keywords" content="clean jewelry designs, elegant jewelry, LalaDiamond, fine jewelry" />
                <meta property="og:title" content="Clean and Elegant Jewelry Designs - LalaDiamond" />
                <meta property="og:description" content="Explore the latest collection of clean and elegant jewelry designs at LalaDiamond. Each piece is crafted with precision and passion to adorn you with elegance and grace." />
                <meta property="og:image" content="%PUBLIC_URL%/path-to-your-image.jpg" />
                <meta property="og:url" content="http://www.laladiamond.com/clean-elegant-designs" />
            </Helmet>
            <div className="intro-container">
                <p className="introtext">Lastest Collection</p>
                <h1 className='intro-text'>Clean and Elegant Design</h1>
            </div>

            <div className='content-container'>
                <div className='bgimages'>
                    <img src={img} alt='Not found'></img>
                </div>
                <div className='mainintroduction'>
                    <div>
                        <p className='introdetail'>one & only</p>
                        <h2 className='intro'>TRUE UNIQUE
                            STYLE</h2>
                        <p className='introduction'>At LalaDiamond, we believe in the timeless allure of fine jewelry. Our exquisite collection is crafted with precision and passion to adorn you with elegance and grace. Each piece tells a story, celebrating moments of love, beauty, and sophistication.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
