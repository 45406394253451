import React, { useEffect, useRef } from 'react';
import './Carousel.css'; // Import your CSS file for carousel styling
import image1 from "../../image/scroll/1.jpg";
import image2 from "../../image/scroll/2.webp";
import image3 from "../../image/scroll/3.jpg";
import image4 from "../../image/scroll/4.webp";
import image5 from "../../image/scroll/5.jpg";
import image6 from "../../image/scroll/6.webp";
import image7 from "../../image/scroll/7.webp";
import image8 from "../../image/scroll/8.webp";
import image9 from "../../image/scroll/9.webp";
import image10 from "../../image/scroll/10.webp";
import image11 from "../../image/scroll/11.webp";
import image12 from "../../image/scroll/12.jpg";
import image13 from "../../image/scroll/13.png";

const images = [
    { src: image1, alt: "Elegant diamond ring" },
    { src: image2, alt: "Beautiful diamond necklace" },
    { src: image3, alt: "Stylish diamond earrings" },
    { src: image4, alt: "Luxury diamond bracelet" },
    { src: image5, alt: "Classic diamond pendant" },
    { src: image6, alt: "Exquisite diamond brooch" },
    { src: image7, alt: "Fancy diamond anklet" },
    { src: image8, alt: "Charming diamond tiara" },
    { src: image9, alt: "Delicate diamond bangle" },
    { src: image10, alt: "Sparkling diamond choker" },
    { src: image11, alt: "Dazzling diamond studs" },
    { src: image12, alt: "Intricate diamond cuff" },
    { src: image13, alt: "Gorgeous diamond headpiece" }
];

function Carousel() {
    const carouselRef = useRef(null);

    useEffect(() => {
        const carousel = carouselRef.current;

        const animateScroll = () => {
            carousel.scrollLeft += 1;
            if (carousel.scrollLeft >= carousel.scrollWidth - carousel.offsetWidth) {
                carousel.scrollLeft = 0;
            }
        };

        const intervalId = setInterval(animateScroll, 20);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="carousel" ref={carouselRef}>
            <div className="carousel-inner">
                {images.map((image, index) => (
                    <img src={image.src} className="carousel-item" alt={image.alt} key={index} />
                ))}
                {images.map((image, index) => (
                    <img src={image.src} className="carousel-item" alt={image.alt} key={index + images.length} />
                ))}
            </div>
        </div>
    );
}

export default Carousel;
