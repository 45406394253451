import React, { useState, useEffect } from 'react';
import image1 from "../../image/1.svg";
import image2 from "../../image/2.svg";
import image3 from "../../image/3.svg";
import image4 from "../../image/4.svg";
import image5 from "../../image/5.svg";
import './teplete.css'; // Import the CSS file

const images = [image1, image2, image3, image4, image5];

export default function Index() {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000); // Change image every 3 seconds

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    const handleControlClick = (index) => {
        setCurrentImageIndex(index);
    };

    return (
        <div className="container">
            <div className="image-container">
                <img
                    src={images[currentImageIndex]}
                    className="slideshow-image"
                    alt="Slideshow"
                />
            </div>
            <div className="slideshow-controls">
                {images.map((_, index) => (
                    <div key={index} onClick={() => handleControlClick(index)}>
                        {index === 0 && 'RING'}
                        {index === 2 && 'BRACELETS'}
                        {index === 1 && 'EARRINGS'}
                        {index === 3 && 'PENDANTS'}
                        {index === 4 && 'DIAMONDS'}
                    </div>
                ))}
            </div>
        </div>
    );
}
